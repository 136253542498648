import { useState } from "react";
import styles from "./Calculators.module.css";

import Select from "../components/Select";
import InputNumber from "../components/InputNumber";
import Output from "../components/Output";

import { concreteBeamBending } from "../../../utils/services/calculators";

import Beam from "../components/calculators/concrete/Beam";

function Calculators({ calculators, getProductNavigate }) {
  const [selectedCalculator, setSelectedCalculator] = useState("menu");
  const [inputs, setInputs] = useState({
    fck: 20,
    fyk: 25,
    md: 0,
    b: 0,
    h: 0,
    dl: 0,
  });
  const [outputs, setOutputs] = useState({
    ln: 0,
    domain: 0,
    ast: 0,
    asc: 0,
  });

  function updateInputs(name, value) {
    setInputs((prevInputs) => ({ ...prevInputs, [name]: parseFloat(value) }));
    setOutputs({
      ln: 0,
      domain: 0,
      ast: 0,
      asc: 0,
    });
  }

  function onCalculate() {
    concreteBeamBending(inputs).then((response) => {
      setOutputs(response["data"]);
    });
  }

  return (
    <>
      {selectedCalculator === "menu" && (
        <>
          {/* UTILIZAR MESMA ESTRUTURA DO HOME DA HOMEPAGE PARA JOGAR O USUARIOS PARA DIFERENTES ABAS DAS CALCULADORAS */}
          <div className={styles.section}>
            <h1>Calculadoras disponíveis</h1>
            <div className={styles.separator}></div>
            <div className={styles.cards}>
              {Object.entries(calculators).map(([solution, infos], i) => {
                if (infos["version"] !== "unavailable") {
                  return (
                    <div className={styles.card} key={i}>
                      <div className={styles.cardHeader}>
                        <img
                          src={require("../../../utils/img/" + infos["icon"])}
                          alt={`${solution} icon`}
                        ></img>
                        <h1>{solution}</h1>
                      </div>
                      {infos["calculators"].map((calculator, j) => (
                        <div
                          className={
                            calculator["version"] === "available"
                              ? styles.cardButton
                              : styles.disabledCardButton
                          }
                          key={i + j}
                          onClick={() =>
                            setSelectedCalculator(calculator["calculator"])
                          }
                        >
                          {calculator["name"]}
                        </div>
                      ))}
                    </div>
                  );
                }
              })}
            </div>
          </div>
          <div className={styles.section}>
            <h1>Calculadoras em desenvolvimento</h1>
            <div className={styles.separator}></div>
            <div className={styles.cards}>
              {Object.entries(calculators).map(([solution, infos], i) => {
                if (infos["version"] === "unavailable") {
                  return (
                    <div className={styles.disabledCard} key={i}>
                      <div className={styles.cardHeader}>
                        <img
                          src={require("../../../utils/img/" + infos["icon"])}
                          alt={`${solution} icon`}
                        ></img>
                        <h1>{solution}</h1>
                      </div>
                      {infos["calculators"].map((calculator, j) => (
                        <div
                          className={
                            calculator["version"] === "available"
                              ? styles.cardButton
                              : styles.disabledCardButton
                          }
                          key={i + j}
                        >
                          {calculator["name"]}
                        </div>
                      ))}
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </>
      )}
      {selectedCalculator === "concrete-beam" && <Beam />}
    </>
  );
}

export default Calculators;
