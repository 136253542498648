import styles from "./Select.module.css";

function Select({ text, elements, unit, onChange }) {
  return (
    <form className={styles.selectComponent}>
      <label className={styles.componentText}>{text} =</label>
      <select className={styles.componentElements} onChange={onChange}>
        {elements.map((element, i) => (
          <option key={i} value={element}>
            {element}
          </option>
        ))}
      </select>
      <label className={styles.componentUnit}>{unit ? unit : ""}</label>
    </form>
  );
}

export default Select;
