import styles from "./Support.module.css";

import { Box, Flex, Text } from "@chakra-ui/react";

function Support({}) {
  return (
    <div className={styles.section}>
      <h1>
        Está com alguma dificuldade em utilizar as nossas ferramentas ou possui
        um feedback/sugestão para nos dar?
      </h1>
      <p>
        Envie um email com a dúvida ou a sugestão para:
        <strong> engenharssuporte@gmail.com</strong>
      </p>
      <p>
        Valorizamos muito a satisfação dos usuários e para isso gostaríamos que
        você participasse do desenvolvimento das nossas ferramentas!
      </p>
    </div>
  );
}

export default Support;
