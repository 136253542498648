import { useState } from "react";
import styles from "../../../containers/Calculators.module.css";

import Select from "../../Select";
import InputNumber from "../../InputNumber";
import Output from "../../Output";

import {
  concreteBeamBending,
  concreteBeamShear,
} from "../../../../../utils/services/calculators";
import { Text } from "@chakra-ui/react";

function Beam({}) {
  // BENDING
  const [bendingInputs, setBendingInputs] = useState({
    fck: 20,
    fyk: 25,
    md: 0,
    b: 0,
    h: 0,
    dl: 0,
  });
  const [bendingOutputs, setBendingOutputs] = useState({
    ln: 0,
    domain: 0,
    ast_min: 0,
    ast: 0,
    asc: 0,
  });

  function updateBendingInputs(name, value) {
    setBendingInputs((prevInputs) => ({
      ...prevInputs,
      [name]: parseFloat(value),
    }));
    setBendingOutputs({
      ln: 0,
      domain: 0,
      ast_min: 0,
      ast: 0,
      asc: 0,
    });
  }

  function onBendingCalculate() {
    concreteBeamBending(bendingInputs).then((response) => {
      setBendingOutputs(response["data"]);
    });
  }

  // SHEAR
  const [shearInputs, setShearInputs] = useState({
    fck: 20,
    fyk: 25,
    vd: 0,
    b: 0,
    h: 0,
    dl: 0,
    stress_type: 1,
    model: 1,
    teta: 45,
  });
  const [shearOutputs, setShearOutputs] = useState({
    vrd2: 0,
    vc: 0,
    vsw: 0,
    asw_min: 0,
    asw: 0,
  });

  function updateShearInputs(name, value) {
    setShearInputs((prevInputs) => ({
      ...prevInputs,
      [name]: parseFloat(value),
    }));
    setShearOutputs({
      vrd2: 0,
      vc: 0,
      vsw: 0,
      asw_min: 0,
      asw: 0,
    });
  }

  function onShearCalculate() {
    concreteBeamShear(shearInputs).then((response) => {
      console.log(response["data"]);
      console.log(shearInputs["vd"]);
      setShearOutputs(response["data"]);
    });
  }

  // TORSION

  return (
    <>
      <div className={styles.section}>
        <h1>Flexão simples para seção retangular</h1>
        <div className={styles.separator}></div>
        <div className={styles.calculator}>
          <div className={styles.inputs}>
            <Select
              text="fck"
              elements={[20, 25, 30, 35, 40, 45, 50]}
              unit="Mpa"
              onChange={(e) => updateBendingInputs("fck", e.target.value)}
            />
            <Select
              text="fyk"
              elements={[25, 50, 60]}
              onChange={(e) => updateBendingInputs("fyk", e.target.value)}
            />
            <InputNumber
              text="Md"
              unit="tfm"
              onChange={(e) => updateBendingInputs("md", e.target.value)}
            />
            <InputNumber
              text="b"
              unit="cm"
              onChange={(e) => updateBendingInputs("b", e.target.value)}
            />
            <InputNumber
              text="h"
              unit="cm"
              onChange={(e) => updateBendingInputs("h", e.target.value)}
            />
            <InputNumber
              text="dl"
              unit="cm"
              onChange={(e) => updateBendingInputs("dl", e.target.value)}
            />
          </div>
          <div className={styles.buttons}>
            <button
              className={styles.calculateButton}
              onClick={onBendingCalculate}
            >
              CALCULAR
            </button>
          </div>
          <div className={styles.outputs}>
            <Text>{bendingOutputs["ln_text"] + bendingOutputs["ln"]}</Text>
            <Output text="Domínio" value={bendingOutputs["domain"]} />
            <Output
              text="As,mín"
              value={bendingOutputs["ast_min"]}
              unit="cm²"
            />
            <Output text="As" value={bendingOutputs["ast"]} unit="cm²" />
            <Output text="As'" value={bendingOutputs["asc"]} unit="cm²" />
          </div>
        </div>
      </div>
      <div className={styles.section}>
        <h1>Cortante para seção retangular</h1>
        <div className={styles.separator}></div>
        <div className={styles.calculator}>
          <div className={styles.inputs}>
            <Select
              text="fck"
              elements={[20, 25, 30, 35, 40, 45, 50]}
              unit="Mpa"
              onChange={(e) => updateShearInputs("fck", e.target.value)}
            />
            <Select
              text="fyk"
              elements={[25, 50, 60]}
              onChange={(e) => updateShearInputs("fyk", e.target.value)}
            />
            <InputNumber
              text="Vd"
              unit="tf"
              onChange={(e) => updateShearInputs("vd", e.target.value)}
            />
            <InputNumber
              text="b"
              unit="cm"
              onChange={(e) => updateShearInputs("b", e.target.value)}
            />
            <InputNumber
              text="h"
              unit="cm"
              onChange={(e) => updateShearInputs("h", e.target.value)}
            />
            <InputNumber
              text="dl"
              unit="cm"
              onChange={(e) => updateShearInputs("dl", e.target.value)}
            />
          </div>
          <div className={styles.buttons}>
            <button
              className={styles.calculateButton}
              onClick={onShearCalculate}
            >
              CALCULAR
            </button>
          </div>
          <div className={styles.outputs}>
            <Output
              text={shearInputs["vd"] + " <="}
              value={shearOutputs["vrd2"]}
              unit={"OK"}
            />
            <Output
              text={shearInputs["vd"] + " ="}
              value={shearOutputs["vc"] + " + " + shearOutputs["vsw"]}
            />
            <Output
              text="Asw,mín"
              value={shearOutputs["asw_min"]}
              unit="cm²/m"
            />
            <Output text="Asw" value={shearOutputs["asw"]} unit="cm²/m" />
          </div>
        </div>
      </div>
    </>
  );
}

export default Beam;
