import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import styles from "./Main.module.css";

import { api } from "../../utils/services/api";

import Sidebar from "../../components/Sidebar";
import icon from "../homepage/utils/img/fundars-icon.png";

import { ReactComponent as GeotechnicsIcon } from "./utils/img/drilling-rig.svg";
import { ReactComponent as StructureIcon } from "./utils/img/building.svg";

import { FaRegBuilding } from "react-icons/fa";
import { FaClipboardList } from "react-icons/fa";
import { IoIosSettings } from "react-icons/io";

import Project from "./pages/Project";
import Geotechnics from "./pages/Geotechnics";
import Solution from "./pages/Solution";
import Config from "./pages/Config";

import parametersMethods from "./utils/data/parametersMethods.json";
import dataGeotechnics from "./utils/data/dataGeotechnics.json";

const initialGeotechnicsInputs = {
  metodo: "Aoki-Velloso",
  tipo: "Escavada",
  esforco: "compressao",
  dimensao_1: "",
  dimensao_2: "",
  dimensao_3: "0",
  dimensao_4: "0",
};

function Main({ userInputs }) {
  // PROJECT
  const [projectInputs, setProjectInputs] = useState({
    selected_name: "",
    name_input: "",
  });

  function updateProjectInputs(key, value) {
    setProjectInputs((prevInputs) => ({ ...prevInputs, [key]: value }));
  }

  // GEOTECHNICS
  const [investigationInputs, setInvestigationInputs] = useState({
    selected_name: "",
    name_input: "",
  });
  const [investigationsData, setInvestigationsData] = useState([]);
  const [foundationClass, setFoundationClass] = useState("estacas");
  const [geotechnicsInputs, setGeotechnicsInputs] = useState(
    initialGeotechnicsInputs
  );
  const [geotechnicsData, setGeotechnicsData] = useState([{}]);

  function onClearGeotechnics() {
    geotechnicsData.map((layer, i) => {
      layer["Resistência Lateral"] = "";
      layer["Resistência Lateral Acumulada"] = "";
      layer["Resistência Ponta"] = "";
      layer["C. Rup. (kN)"] = "";
      layer["C. Adm. (kN)"] = "";
    });
  }

  function updateInvestigationInputs(key, value) {
    setInvestigationInputs((prevInputs) => ({ ...prevInputs, [key]: value }));
  }

  function updateGeotechnicsInputs(name, value) {
    setGeotechnicsInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
    onClearGeotechnics();
  }

  useEffect(() => {
    if (
      projectInputs["selected_name"] !== "" &&
      investigationInputs["selected_name"] &&
      investigationInputs["selected_name"] !== ""
    ) {
      api
        .post("/layer", [userInputs, projectInputs, investigationInputs])
        .then((response) => {
          const data = [];
          response["data"].map((layer, _) => {
            data.push(
              Object.assign(layer, dataGeotechnics[foundationClass][0])
            );
          });
          setGeotechnicsData(data);
        });
    }
  }, [investigationInputs["selected_name"]]);

  // CONFIG
  const [methodsData, setMethodsData] = useState(parametersMethods);

  useEffect(() => {
    api.post("/method", userInputs).then((response) => {
      setMethodsData((prevMethodsData) => {
        const updatedMethodsData = { ...parametersMethods };
        response["data"].map((foundationClass) => {
          const { estacas, sapatas } = foundationClass;

          if (estacas) {
            updatedMethodsData.estacas = {
              ...updatedMethodsData.estacas,
              ...estacas,
            };
          }

          if (sapatas) {
            updatedMethodsData.sapatas = {
              ...updatedMethodsData.sapatas,
              ...sapatas,
            };
          }
        });
        return updatedMethodsData;
      });
    });
    return;
  }, []);

  // MAIN
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState("Projetos");
  const pages = {
    Projetos: {
      icon: <FaRegBuilding size="35px" />,
      action: () => {
        updateProjectInputs("selected_name", "");
        updateInvestigationInputs("selected_name", "");
        setSelectedOption("Projetos");
      },
      disabled: false,
      component: (
        <Project
          userInputs={userInputs}
          setSelectedOption={setSelectedOption}
          projectInputs={projectInputs}
          updateProjectInputs={updateProjectInputs}
        />
      ),
    },
    Geotecnia: {
      icon: <GeotechnicsIcon width="40px" />,
      action: () => setSelectedOption("Geotecnia"),
      disabled: true,
      component: (
        <Geotechnics
          userInputs={userInputs}
          projectInputs={projectInputs}
          investigationInputs={investigationInputs}
          updateInvestigationInputs={updateInvestigationInputs}
          investigationsData={investigationsData}
          setInvestigationsData={setInvestigationsData}
          foundationClass={foundationClass}
          setFoundationClass={setFoundationClass}
          geotechnicsInputs={geotechnicsInputs}
          updateGeotechnicsInputs={updateGeotechnicsInputs}
          geotechnicsData={geotechnicsData}
          setGeotechnicsData={setGeotechnicsData}
          onClearGeotechnics={onClearGeotechnics}
          methodsData={methodsData}
        />
      ),
    },
    Solução: {
      icon: <StructureIcon width="40px" />,
      action: () => setSelectedOption("Solução"),
      disabled: true,
      component: (
        <Solution
          userInputs={userInputs}
          projectInputs={projectInputs}
          geotechnicsInputs={geotechnicsInputs}
          geotechnicsData={geotechnicsData}
          investigationInputs={investigationInputs}
          foundationClass={foundationClass}
        />
      ),
    },
    Configurações: {
      icon: <IoIosSettings size="35px" />,
      action: () => setSelectedOption("Configurações"),
      disabled: false,
      component: (
        <Config
          userInputs={userInputs}
          methodsData={methodsData}
          setMethodsData={setMethodsData}
        />
      ),
    },
  };

  return userInputs["id"] !== null ? (
    <div className={styles.bodyFundars}>
      <Sidebar
        icon={icon}
        pages={pages}
        selected={selectedOption}
        condition={
          selectedOption !== "Projetos" && selectedOption !== "Configurações"
        }
      />
      {pages[selectedOption]["component"]}
    </div>
  ) : (
    navigate("/")
  );
}

export default Main;
