import styles from "./Softwares.module.css";

function Softwares({ softwares, getSoftwareNavigate }) {
  return (
    <>
      <div className={styles.section}>
        <h1>Programas disponíveis</h1>
        <div className={styles.separator}></div>
        <div className={styles.cards}>
          {Object.entries(softwares).map(([software, infos], i) => {
            if (infos["version"] !== "unavailable") {
              return (
                <button
                  key={i}
                  className={styles.card}
                  onClick={() => getSoftwareNavigate(software)}
                >
                  <div className={styles.cardHeader}>
                    <img
                      src={require("../../../utils/img/" + infos["icon"])}
                      alt={`${software} icon`}
                    ></img>
                    <h1>{software}</h1>
                    {infos["version"] === "beta" && (
                      <span className={styles.cardHeaderTag}>Beta</span>
                    )}
                  </div>
                  <div className={styles.cardContent}>
                    <ul>
                      {infos["description"].map((description, j) => (
                        <li key={j}>{description}</li>
                      ))}
                    </ul>
                  </div>
                  {infos["version"] === "beta" && (
                    <div className={styles.cardContentNotice}>
                      Essa é uma versão de teste pois o produto ainda está em
                      desenvolvimento
                    </div>
                  )}
                </button>
              );
            }
          })}
        </div>
      </div>
      <div className={styles.section}>
        <h1>Próximos lançamentos</h1>
        <div className={styles.separator}></div>
        <div className={styles.cards}>
          {Object.entries(softwares).map(([software, infos], i) => {
            if (infos["version"] === "unavailable") {
              return (
                <button
                  key={i}
                  className={styles.disabledCard}
                  onClick={() => getSoftwareNavigate(software)}
                >
                  <div className={styles.cardHeader}>
                    <img
                      src={require("../../../utils/img/" + infos["icon"])}
                      alt={`${software} icon`}
                    ></img>
                    <h1>{software}</h1>
                  </div>
                  <div className={styles.cardContent}>
                    <ul>
                      {infos["description"].map((description, j) => (
                        <li key={j}>{description}</li>
                      ))}
                    </ul>
                  </div>
                </button>
              );
            }
          })}
        </div>
      </div>
    </>
  );
}

export default Softwares;
