import styles from "./Output.module.css";

function Output({ text, value, unit }) {
  const formatValue = (value) => {
    if (typeof value === "number" && value !== 0 && !isNaN(value)) {
      return value.toFixed(2);
    }
    return value; // Se não for número, retorna o valor original
  };

  return (
    <form className={styles.outputComponent}>
      <label className={styles.componentText}>{text} =</label>
      {value !== 0 && (
        <>
          <label className={styles.componentValue}>{formatValue(value)}</label>
          <label className={styles.componentUnit}>{unit ? unit : ""}</label>
        </>
      )}
    </form>
  );
}

export default Output;
