import styles from "./InputNumber.module.css";

function InputNumber({ text, unit, onChange }) {
  return (
    <form className={styles.inputNumberComponent}>
      <label className={styles.componentText}>{text} =</label>
      <input
        className={styles.componentInput}
        onKeyPress={(event) => {
          if (!/[0-9.]/.test(event.key)) {
            event.preventDefault();
          }
        }}
        onChange={onChange}
      ></input>
      <label className={styles.componentUnit}>{unit ? unit : ""}</label>
    </form>
  );
}

export default InputNumber;
