import { useEffect, useRef, useState } from "react";
import styles from "./Login.module.css";

import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import * as jwt_decode from "jwt-decode";

import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Text,
  useToast,
} from "@chakra-ui/react";

import { api } from "../../../../../utils/services/api";

function Login({ updateUserInputs, setSelectedTab }) {
  const toast = useToast();
  const [userExists, setUserExists] = useState(true);
  const [loginInputs, setLoginInputs] = useState({
    email: "",
    password: "",
  });
  const [registerInputs, setRegisterInputs] = useState({
    name: "",
    email: "",
    password: "",
  });

  function updateLoginInputs(key, value) {
    setLoginInputs((prevInputs) => ({ ...prevInputs, [key]: value }));
  }

  function updateRegisterInputs(key, value) {
    setRegisterInputs((prevInputs) => ({ ...prevInputs, [key]: value }));
  }

  function register() {
    if (
      registerInputs["name"] === "" ||
      registerInputs["email"] === "" ||
      registerInputs["password"] === ""
    ) {
      toast({
        title: "Preencha todos os campos",
        status: "warning",
        isClosable: true,
      });
    } else {
      const toastId = toast({
        title: "Cadastrando...",
        description: "Aguarde um momento.",
        status: "loading",
        duration: null,
      });

      api
        .post("/user/register", registerInputs)
        .then((response) => {
          setUserExists(true);
          toast({
            title: "Conta registrada com sucesso",
            status: "success",
            isClosable: true,
          });
          setRegisterInputs({
            name: "",
            email: "",
            password: "",
          });
        })
        .catch((error) => {
          if (error["response"] && error["response"]["status"] === 409) {
            toast({
              title: error["response"]["data"]["error"],
              status: "error",
              isClosable: true,
            });
          } else {
            toast({
              title: "Erro ao logar, tente novamente mais tarde",
              status: "error",
              isClosable: true,
            });
          }
        })
        .finally(() => {
          toast.close(toastId);
        });
    }
  }

  function login() {
    if (loginInputs["email"] === "" || loginInputs["password"] === "") {
      toast({
        title: "Preencha todos os campos",
        status: "warning",
        isClosable: true,
      });
    } else {
      const toastId = toast({
        title: "Logando...",
        description: "Aguarde um momento.",
        status: "loading",
        duration: null,
      });
      api
        .post("/user/login", loginInputs)
        .then((response) => {
          updateUserInputs("id", response["data"]["id"]);
          updateUserInputs("name", response["data"]["name"]);
          updateUserInputs("email", response["data"]["email"]);
          window.localStorage.setItem("user_id", response["data"]["id"]);
          window.localStorage.setItem("user_name", response["data"]["name"]);
          window.localStorage.setItem("user_email", response["data"]["email"]);
          setSelectedTab("Softwares");
          toast({
            title: "Login realizado com sucesso",
            status: "success",
            isClosable: true,
          });
          setLoginInputs({
            email: "",
            password: "",
          });
        })
        .catch((error) => {
          if (error["response"] && error["response"]["status"] === 401) {
            toast({
              title: error["response"]["data"]["error"],
              status: "error",
              isClosable: true,
            });
          } else {
            toast({
              title: "Erro ao logar, tente novamente mais tarde",
              status: "error",
              isClosable: true,
            });
          }
        })
        .finally(() => {
          toast.close(toastId);
        });
    }
  }

  function loginGoogle(google_info) {
    const toastId = toast({
      title: "Logando...",
      description: "Aguarde um momento.",
      status: "loading",
      duration: null,
    });
    const user_info = jwt_decode.jwtDecode(google_info.credential);
    api
      .post("/user/login/google", user_info)
      .then((response) => {
        updateUserInputs("id", response["data"]["id"]);
        updateUserInputs("name", response["data"]["name"]);
        updateUserInputs("email", response["data"]["email"]);
        window.localStorage.setItem("user_id", response["data"]["id"]);
        window.localStorage.setItem("user_name", response["data"]["name"]);
        window.localStorage.setItem("user_email", response["data"]["email"]);
        setSelectedTab("Softwares");
        if (response && response["status"] === 200) {
          toast({
            title: "Login realizado com sucesso",
            status: "success",
            isClosable: true,
          });
        } else {
          toast({
            title: "Conta registrada com sucesso",
            status: "success",
            isClosable: true,
          });
        }
      })
      .catch((error) => {
        if (error["response"] && error["response"]["status"] === 401) {
          toast({
            title: error["response"]["data"]["error"],
            status: "error",
            isClosable: true,
          });
        } else {
          toast({
            title: "Erro ao logar, tente novamente mais tarde",
            status: "error",
            isClosable: true,
          });
        }
      })
      .finally(() => {
        toast.close(toastId);
      });
  }

  return (
    <>
      {userExists ? (
        <div className={styles.section}>
          <FormControl w="300px" padding="5px 0px">
            <FormLabel>Email:</FormLabel>
            <Input
              value={loginInputs["email"]}
              onChange={(ev) => updateLoginInputs("email", ev.target.value)}
            />
          </FormControl>
          <FormControl w="300px" padding="5px 0px">
            <FormLabel>Senha:</FormLabel>
            <Input
              type="password"
              value={loginInputs["password"]}
              onChange={(ev) => updateLoginInputs("password", ev.target.value)}
            />
          </FormControl>
          <FormControl
            w="300px"
            padding="5px 0px"
            display="flex"
            justifyContent="flex-end"
          >
            <Button w="100%" onClick={login}>
              Entrar
            </Button>
          </FormControl>
          <FormControl
            w="300px"
            padding="5px 0px"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <FormLabel display="flex" textAlign="center">
              Ou prossiga com:
            </FormLabel>
            <GoogleOAuthProvider clientId="197839581355-u4s6jhdp7p4hmfgcc14geh4kvoc7o2pl.apps.googleusercontent.com">
              <GoogleLogin
                onSuccess={loginGoogle}
                onError={(error) => console.log("O login falhou:", error)}
              />
            </GoogleOAuthProvider>
          </FormControl>
          <FormControl
            w="300px"
            padding="5px 0px"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <FormLabel display="flex" textAlign="center">
              Ainda não possui uma conta?
            </FormLabel>
            <Button
              variant="outline"
              onClick={() => {
                setUserExists(false);
                setLoginInputs({
                  email: "",
                  password: "",
                });
              }}
            >
              Registrar-se
            </Button>
          </FormControl>
        </div>
      ) : (
        <div className={styles.section}>
          <FormControl w="300px" padding="5px 0px">
            <FormLabel>Nome:</FormLabel>
            <Input
              value={registerInputs["name"]}
              onChange={(ev) => updateRegisterInputs("name", ev.target.value)}
            />
          </FormControl>
          {/* <FormControl w="300px" padding="5px 0px">
            <FormLabel>Logo:</FormLabel>
            <Input
              type="image"
              value={registerInputs["image"]}
              onChange={(ev) => updateRegisterInputs("image", ev.target.value)}
            />
          </FormControl> */}
          <FormControl w="300px" padding="5px 0px">
            <FormLabel>Email:</FormLabel>
            <Input
              value={registerInputs["email"]}
              onChange={(ev) => updateRegisterInputs("email", ev.target.value)}
            />
          </FormControl>
          <FormControl w="300px" padding="5px 0px">
            <FormLabel>Senha:</FormLabel>
            <Input
              type="password"
              value={registerInputs["password"]}
              onChange={(ev) =>
                updateRegisterInputs("password", ev.target.value)
              }
            />
          </FormControl>
          <FormControl
            w="300px"
            padding="5px 0px"
            display="flex"
            justifyContent="flex-end"
          >
            <Button w="100%" onClick={register}>
              Registrar
            </Button>
          </FormControl>
          <FormControl
            w="300px"
            padding="5px 0px"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <FormLabel display="flex" textAlign="center">
              Já possui uma conta?
            </FormLabel>
            <Button
              variant="outline"
              onClick={() => {
                setUserExists(true);
                setRegisterInputs({
                  name: "",
                  email: "",
                  password: "",
                });
              }}
            >
              Logar
            </Button>
          </FormControl>
        </div>
      )}
    </>
  );
}

export default Login;
