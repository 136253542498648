import { useState } from "react";
import styles from "./ColorModeButton.module.css";

import sun from "../../../utils/img/sun.png";
import moon from "../../../utils/img/moon.png";

function ColorModeButton({}) {
  const [colorMode, setColorMode] = useState("light");

  const handleChangeColorMode = () => {
    const currentColorMode = document
      .querySelector("html")
      .getAttribute("data-theme");
    const newMode = currentColorMode === "light" ? "dark" : "light";
    document.querySelector("html").setAttribute("data-theme", newMode);
    setColorMode(newMode);
  };

  return (
    <div className={styles.colorMode}>
      <span className={styles.colorModeText}></span>
      <button
        className={styles.colorModeButton}
        onClick={handleChangeColorMode}
      >
        <img
          src={colorMode === "light" ? moon : sun}
          alt={colorMode}
          className={styles.colorModeImage}
        />
      </button>
    </div>
  );
}

export default ColorModeButton;
