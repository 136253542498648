import { useState, useEffect } from "react";

import {
  Button,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  useToast,
} from "@chakra-ui/react";
import {
  AddIcon,
  CheckIcon,
  CloseIcon,
  DeleteIcon,
  EditIcon,
  SearchIcon,
} from "@chakra-ui/icons";

import styles from "../Main.module.css";

import { api } from "../../../utils/services/api";
import {
  projectRegister,
  projectEdit,
  projectRemove,
} from "../utils/services/projects";

function Project({
  userInputs,
  setSelectedOption,
  projectInputs,
  updateProjectInputs,
}) {
  const toast = useToast();
  const [projectsData, setProjectsData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [formOpen, setFormOpen] = useState("");

  function onProjectInputChange(ev) {
    const value = ev.target.value;
    updateProjectInputs("name_input", value);
  }

  function onSearchInputChange(ev) {
    const value = ev.target.value;
    setSearchTerm(value);
    const filter = projectsData.filter((name) =>
      name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredProjects(filter);
    updateProjectInputs("selected_name", "");
  }

  function onOpenProject() {
    if (projectInputs["selected_name"] !== "") {
      setSelectedOption("Geotecnia");
    } else {
      toast({
        title: "Para executar essa função, selecione um projeto",
        status: "warning",
        isClosable: true,
      });
    }
  }

  function onProjectAction(action) {
    const options = {
      register: async () => {
        if (projectInputs["name_input"] === "") {
          toast({
            title: "Preencha todos os campos",
            status: "warning",
            isClosable: true,
          });
        } else if (
          projectsData.some((name) => name === projectInputs.name_input)
        ) {
          toast({
            title:
              "Um projeto com esse nome já existe. Escolha um nome diferente",
            status: "error",
            isClosable: true,
          });
        } else {
          const toastId = toast({
            title: "Cadastrando o projeto...",
            description: "Aguarde enquanto os dados são processados.",
            status: "loading",
            duration: null,
          });

          try {
            await projectRegister([userInputs, projectInputs]);
            // Optimistic update
            const updatedProjectsData = [...projectsData];
            updatedProjectsData.push(projectInputs["name_input"]);
            setProjectsData(updatedProjectsData);
            setFilteredProjects(updatedProjectsData);
            setSearchTerm("");
            toast({
              title: "Projeto criado com sucesso",
              status: "success",
              isClosable: true,
            });
          } catch (error) {
            toast({
              title: "Erro ao cadastrar o projeto, tente novamente mais tarde",
              status: "error",
              isClosable: true,
            });
          } finally {
            toast.close(toastId);
          }
          updateProjectInputs("name_input", "");
          setFormOpen("");
        }
      },
      edit: async () => {
        if (projectInputs["name_input"] === "") {
          toast({
            title: "Preencha todos os campos",
            status: "warning",
            isClosable: true,
          });
        } else if (
          projectsData.some((name) => name === projectInputs.name_input)
        ) {
          toast({
            title:
              "Um projeto com esse nome já existe. Escolha um nome diferente",
            status: "error",
            isClosable: true,
          });
        } else {
          const toastId = toast({
            title: "Editando o projeto...",
            description: "Aguarde enquanto os dados são processados.",
            status: "loading",
            duration: null,
          });

          try {
            await projectEdit([userInputs, projectInputs]);
            // Optimistic Update
            const updatedProjectsData = [...projectsData];
            updatedProjectsData.map((project, i) => {
              if (projectInputs["selected_name"] === project) {
                updatedProjectsData[i] = projectInputs["name_input"];
              }
            });
            setProjectsData(updatedProjectsData);
            setFilteredProjects(updatedProjectsData);
            setSearchTerm("");
            toast({
              title: "Projeto editado com sucesso",
              status: "success",
              isClosable: true,
            });
          } catch (error) {
            toast({
              title: "Erro ao editar o projeto, tente novamente mais tarde",
              status: "error",
              isClosable: true,
            });
          } finally {
            toast.close(toastId);
          }
          updateProjectInputs("name_input", "");
          updateProjectInputs("selected_name", "");
          setFormOpen("");
        }
      },
      remove: async () => {
        const toastId = toast({
          title: "Removendo o projeto...",
          description: "Aguarde enquanto os dados são processados.",
          status: "loading",
          duration: null,
        });

        try {
          await projectRemove([userInputs, projectInputs]);
          // Optimistic update
          const updatedProjectsData = [...projectsData];
          updatedProjectsData.map((project, i) => {
            if (projectInputs["selected_name"] === project) {
              updatedProjectsData.splice(i, 1);
            }
          });
          setProjectsData(updatedProjectsData);
          setFilteredProjects(updatedProjectsData);
          setSearchTerm("");
          toast({
            title: "Projeto removido com sucesso",
            status: "success",
            isClosable: true,
          });
        } catch (error) {
          toast({
            title: "Erro ao remover o projeto, tente novamente mais tarde",
            status: "error",
            isClosable: true,
          });
        } finally {
          toast.close(toastId);
        }
        updateProjectInputs("selected_name", "");
        setFormOpen("");
      },
    };
    for (const [key, value] of Object.entries(options)) {
      if (action === key) {
        value();
      }
    }
  }

  useEffect(() => {
    const fetchProjects = async () => {
      const toastId = toast({
        title: "Carregando projetos...",
        status: "loading",
        duration: null,
      });

      api
        .post("/project", userInputs)
        .then((response) => {
          setProjectsData(response["data"]);
          setFilteredProjects(response["data"]);
        })
        .catch((error) => {
          toast({
            title: "Erro ao carregar as obras",
            description: "Por favor, tente novamente mais tarde.",
            status: "error",
            isClosable: true,
          });
        })
        .finally(() => {
          toast.close(toastId);
        });
    };

    fetchProjects();
  }, []);

  return (
    <>
      <div className={styles.mainProject}>
        <div className={styles.projectHeader}>
          <div className={styles.projectSearch}>
            <InputGroup size="md">
              <InputLeftAddon children={<SearchIcon />} />
              <Input
                type="text"
                placeholder="Buscar projeto"
                value={searchTerm}
                onChange={onSearchInputChange}
              />
            </InputGroup>
          </div>
          <div className={styles.projectButtons}>
            {(formOpen === "register" || formOpen === "edit") && (
              <>
                <Input
                  type="text"
                  placeholder="Digite o nome do projeto"
                  onChange={onProjectInputChange}
                />
                <IconButton
                  variant="icon"
                  icon={<CheckIcon />}
                  onClick={() => onProjectAction(formOpen)}
                />
                <IconButton
                  variant="icon"
                  icon={<CloseIcon />}
                  onClick={() => {
                    updateProjectInputs("selected_name", "");
                    setFormOpen("");
                  }}
                />
              </>
            )}
            <Tooltip label="Criar" placement="bottom">
              <IconButton
                variant="icon"
                icon={<AddIcon />}
                onClick={() => setFormOpen("register")}
              />
            </Tooltip>
            <Tooltip label="Editar" placement="bottom">
              <IconButton
                variant="icon"
                icon={<EditIcon />}
                onClick={() => {
                  if (projectInputs["selected_name"] === "") {
                    toast({
                      title: "Para executar essa função, selecione um projeto",
                      status: "warning",
                      isClosable: true,
                    });
                  } else {
                    setFormOpen("edit");
                  }
                }}
              />
            </Tooltip>
            <Tooltip label="Remover" placement="bottom">
              <IconButton
                variant="icon"
                icon={<DeleteIcon />}
                onClick={() => {
                  if (projectInputs["selected_name"] === "") {
                    toast({
                      title: "Para executar essa função, selecione um projeto",
                      status: "warning",
                      isClosable: true,
                    });
                  } else {
                    setFormOpen("remove");
                  }
                }}
              />
            </Tooltip>
          </div>
        </div>
        <Table>
          <Thead>
            <Tr>
              <Th>PROJETOS</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredProjects.map((name, i) => {
              return (
                <Tr
                  aria-selected={
                    projectInputs["selected_name"] === name ? "true" : "false"
                  }
                  key={"row" + i}
                >
                  <Td
                    onClick={() => updateProjectInputs("selected_name", name)}
                  >
                    {name}
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        <div className={styles.projectFooter}>
          <Button onClick={onOpenProject}>Abrir Projeto</Button>
        </div>
      </div>
      {formOpen === "remove" && projectInputs["selected_name"] !== "" && (
        <Modal isCentered isOpen={formOpen !== ""}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Excluir {projectInputs["selected_name"]}</ModalHeader>
            <ModalCloseButton
              onClick={() => {
                setFormOpen("");
              }}
            />
            <ModalBody>
              Deseja prosseguir? Essa ação não poderá ser desfeita
            </ModalBody>
            <ModalFooter>
              <Button
                variant="delete"
                onClick={() => onProjectAction(formOpen)}
              >
                EXCLUIR
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
}

export default Project;
